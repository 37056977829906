import React, { useState, useEffect, useRef, forwardRef } from 'react';
import styled from 'styled-components';
import { optimizeName } from "../../other/vod-series-name-optimizer";
import { Link } from "react-router-dom";
import DB from "../../other/local-db";
import { getVodInfo, getSeriesInfo } from "../../other/load-playlist";

const Li = styled(Link)`
  height: calc(13vw * 1.5);
  max-height: 55vh;
  text-align: center;
  flex: 0 0 calc(15.6667% - 1rem);
  max-width: calc(15.6667% - 1rem);
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
  outline-width: 0;
  text-decoration: none !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  z-index: 1;
  margin: 0 0.5rem;
  &:hover, &:focus {
    transform: scale(1.1);
    box-shadow: rgb(255 0 0 / 75%) 0px 3px 10px;
    outline: none;
    z-index: 10;
  }
`;

const ImgContainer = styled.div`
  height: calc(100% - 1.5rem);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: .5rem;
  position: relative;
  transition: box-shadow .3s ease;
  text-decoration: none;
  overflow: hidden;
`;

const Title = styled.label`
  font-size: 1.2rem;
  padding: .1rem;
  line-height: 1.6rem;
  overflow: hidden;
  color: white;
`;

const Bar = styled.div`
  position: absolute;
  display: flex;
  height: 4%;
  bottom: -5%;
  width: calc(100% - .7rem);
  background-color: black;
  border-radius: 10rem;
  & > div {
    height: 100%;
    border-radius: 10rem;
    background-color: var(--second-color);
  }
`;

const Name = styled.div`
  height: 1.5rem;
  color: white;
  width: calc(100%);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: none !important;
  text-align: center;
  text-decoration: none;
  padding-top: 0.1rem;
  position: relative;
  z-index: 2;
  transition: background-color 0.3s ease;
`;

const InfoContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  padding: .5rem;
  color: #fff;
  font-size: 14px;
  text-align: left;
  border-radius: .5rem;
  position: absolute;
  bottom: 15%;
  left: 0;
  right: 0;
  z-index: 10;
  transition: opacity 0.3s ease;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

const InfoTitle = styled.h4`
  margin: 0;
  font-size: 1rem;
  display: ${({ showTitle }) => (showTitle ? "block" : "none")};
`;

const Rating = styled.div`
  font-size: 1.5rem;
  color: #46d369;
`;

const Genres = styled.div`
  font-size: 0.9rem;
  color: #bbb;
`;

const Year = styled.div`
  font-size: 1.5rem;
  color: #f2e205;
`;

const RatingBadge = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #e6b91e;
  color: black;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.9rem;
  z-index: 3;
  display: inline-block;
  opacity: 0.8;
`;

const YearBadge = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #f2e205;
  color: black;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.9rem;
  z-index: 3;
  display: inline-block;
  opacity: 0.8;
`;

const CastList = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  right: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 0.3rem 0.6rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  text-align: center;
  z-index: 3;
  display: inline-block;
  max-height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s ease;
`;

const noCoverStyle = {
  backgroundColor: '#333',
  color: 'white',
  padding: '1rem',
  textAlign: 'center',
  borderRadius: '.5rem'
};

const RowItem = forwardRef(({ name, stream_icon, stream_id, category_id, id, style, isSeries, container_extension, existingTmdb, rowIndex }, ref) => {
  const [noCover, setNoCover] = useState(!stream_icon);
  const [streamStat, setStreamStat] = useState();
  const [showInfo, setShowInfo] = useState(false);
  const [info, setInfo] = useState({});
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    setStreamStat(DB.findOne(isSeries ? "series" : "movie", stream_id, category_id === "fav"));
    return () => {
      isMounted.current = false;
    };
  }, [isSeries, stream_id, category_id]);

  useEffect(() => {
    const cacheKey = `${isSeries ? 'series' : 'movie'}_${stream_id}`;
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      setInfo(JSON.parse(cachedData));
    } else {
      async function fetchData() {
        try {
          const result = await (isSeries
            ? getSeriesInfo(stream_id, name, true, existingTmdb)
            : getVodInfo(stream_id, name, existingTmdb));
          if (result && result.info && isMounted.current) {
            const castArray = typeof result.info.cast === 'string'
              ? result.info.cast.split(',').map(c => c.trim())
              : Array.isArray(result.info.cast)
              ? result.info.cast
              : [];
            const limitedCast = castArray.slice(0, 3);
            const infoData = {
              name: result.info.name || name,
              rating: typeof result.info.rating === 'string' ? parseFloat(result.info.rating).toFixed(1) :
                typeof result.info.rating === 'number' ? result.info.rating.toFixed(1) : null,
              year: result.info.releaseDate ? result.info.releaseDate.slice(0, 4) :
                result.info.releasedate ? result.info.releasedate.slice(0, 4) : '',
              genres: typeof result.info.genre === 'string' ? result.info.genre.split(',').map(g => g.trim()) :
                Array.isArray(result.info.genre) ? result.info.genre : [],
              cast: limitedCast
            };
            setInfo(infoData);
            localStorage.setItem(cacheKey, JSON.stringify(infoData));
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      fetchData();
    }
  }, [stream_id, isSeries, name, existingTmdb]);

  return (
    <Li
      ref={ref}
      className="col-2 vod"
      data-id={id}
      style={style}
      onMouseEnter={() => setShowInfo(true)}
      onMouseLeave={() => setShowInfo(false)}
      onFocus={() => setShowInfo(true)}
      onBlur={() => setShowInfo(false)}
      to={`/${isSeries ? "series" : "movie"}/category/${category_id}/${stream_id}/info/`}
      tabIndex={0}
      data-mainvod="true"
      onKeyDown={(e) => {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          if (rowIndex === 0) {
            const lastNavBarItem = document.querySelector('[data-navbar="true"]');
            if (lastNavBarItem) {
              lastNavBarItem.focus();
            }
          } else {
            const focusableElements = Array.from(document.querySelectorAll('[tabindex="0"]'));
            const currentIndex = focusableElements.indexOf(document.activeElement);
            const itemsPerRow = 6;
            if (currentIndex - itemsPerRow >= 0) {
              focusableElements[currentIndex - itemsPerRow].focus();
            }
          }
        }
      }}
    >
      <ImgContainer style={!noCover ? { backgroundImage: `url(${stream_icon})` } : noCoverStyle}>
        {!noCover ? (
          <>
            <img src={stream_icon} width={0} height={0} onError={() => setNoCover(true)} alt={name} />
            {info.rating && <RatingBadge>{info.rating}</RatingBadge>}
            {info.year && <YearBadge>{info.year}</YearBadge>}
            {info.cast && info.cast.length > 0 && <CastList>{info.cast.join(', ')}</CastList>}
          </>
        ) : (
          <Title>{optimizeName(name)}</Title>
        )}
      </ImgContainer>
      {!noCover && <Name>{optimizeName(name)}</Name>}
      {streamStat && streamStat.tot > 3 && streamStat.tot < 95 && (
        <Bar>
          <div style={{ width: streamStat.tot + "%" }} />
        </Bar>
      )}
      <InfoContainer show={showInfo}>
        <InfoTitle showTitle={Boolean(info.name)}>{info.name}</InfoTitle>
        {info.rating && <Rating>Ocjena: {info.rating}</Rating>}
        {info.year && <Year>Godina: {info.year}</Year>}
        {info.genres && <Genres>Žanrovi: {info.genres.join(', ')}</Genres>}
        {info.cast && <Genres>Glumci: {info.cast.join(', ')}</Genres>}
      </InfoContainer>
    </Li>
  );
});

export default RowItem;