import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { getInfo, logout } from '../other/user_info';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setH24 } from '../actions/h24';

const Container = styled.div`
  background-color: #212529;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    padding: 1rem;
    flex-direction: column;
  }
`;

const Box = styled.div`
  position: relative;
  z-index: 98;
  &:before, &:after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(20, 21, 26, 0.6);
    border-bottom-left-radius: 200%;
    z-index: -1;
    transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1), border-radius 0.8s linear;
    transform: translateX(100%) translateY(-100%);
  }
  &:after {
    background: rgba(9, 9, 12, 1);
    transition-delay: 0s;
  }
  &:before {
    transition-delay: .2s;
  }
`;

const Item = styled.li`
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 1rem;
  border-bottom: 1px solid #626567;
  &:last-child {
    border-bottom: none;
  }
  
  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

const Button = styled.button`
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid #f5365c;
  border-radius: 8px;
  padding: 10px;
  color: white;
  transition: all .3s ease-in-out;
  cursor: pointer;
  &:hover, &:focus {
    background-color: #f5365c;
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
`;

const AccountInfo = () => {
  const [style, setStyle] = useState();
  const info = getInfo();
  const history = useHistory();
  const dispatch = useDispatch();
  const h24 = useSelector(state => state.h24);

  // Refs za navigaciju
  const backButtonRef = useRef(null);
  const logoutButtonRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setStyle("nav-active");
      backButtonRef.current.focus();
    }, 10);

    // Globalni keydown za navigaciju u petlji
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
        if (document.activeElement === backButtonRef.current) {
          logoutButtonRef.current.focus();
        } else {
          backButtonRef.current.focus();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup function
    return () => {
      clearTimeout(timeout);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const back = () => {
    setStyle();
    setTimeout(() => {
      history.goBack();
    }, 600);
  };

  // Formatiranje datuma u DD. MM. YYYY : HH:MM
  const formatExpiryDate = (expiry) => {
    if (!expiry) return "Nema dostupnog datuma";
    const date = new Date(expiry);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}. ${month}. ${year} : ${hours}:${minutes}`;
  };

  return (
    <Container className={style}>
      <Box className="nav">
        <div className="nav__content">
          <ul tabIndex={0} className="nav__list" style={{ padding: "0 20%" }}>
            <Item>Korisničko ime: <span>{info.username}</span></Item>
            <Item>Vrijedi do: <span style={{ marginLeft: "8px" }}>{formatExpiryDate(info.expiry)}</span></Item>
            <Item>
              <Checkbox type="checkbox" defaultChecked={h24 === "HH:MM"} onChange={() => dispatch(setH24(h24 !== "HH:MM"))} id="h24" />
              <label htmlFor="h24">Koristi 24H format</label>
            </Item>
            <Item>
              <Button ref={backButtonRef} id="info-back" onClick={back}>Vrati se nazad</Button>
              <Button ref={logoutButtonRef} style={{ marginLeft: '10px' }} onClick={logout}>Odjava</Button>
            </Item>
          </ul>
        </div>
      </Box>
    </Container>
  );
};

export default AccountInfo;
