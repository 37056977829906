import React, { useState, useEffect, useRef, useCallback } from "react";
import styled, { keyframes } from "styled-components";
import { useHistory, useParams, useLocation } from "react-router-dom";
import "./Search.css";

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  transition: all 0.5s ease;
  opacity: 0;
  @media (max-width: 600px) {
    padding: 1rem;
    justify-content: flex-start;
    flex-direction: column;
  }
`;

const Box = styled.form`
  position: relative;
  width: 50%;
  left: 25%;
  top: 50%;
  display: flex;
  @media (max-width: 600px) {
    width: 90%;
    left: 5%;
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 1rem;
  font-size: 1.2rem;
  background-color: var(--first-color);
  color: white;
  border: none;
  border-radius: 0.4rem;
  &:focus {
    outline: none;
    border-color: var(--second-color);
    box-shadow: 0 0 0 0.2rem var(--second-color-shadow);
    transition: all 0.5s ease;
  }
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover,
  &:focus {
    background-color: #829196;
    border-color: #829196;
    box-shadow: 0 0 0 0.2rem #82919608;
    transition: all 0.5s ease;
  }
`;

const ClearButton = styled(Button)`
  margin-right: 0.3rem;
  background-color: var(--first-color);
  color: white;
`;

const SearchButton = styled(Button)`
  margin-left: 0.3rem;
  background-color: var(--second-color);
  color: white;
`;

const lightning = keyframes`
  0% {
    text-shadow: 0 0 20px white, 0 0 30px blue, 0 0 40px blue, 0 0 50px blue;
  }
  100% {
    text-shadow: 0 0 20px blue, 0 0 30px white, 0 0 40px white, 0 0 50px white;
  }
`;

const spark = keyframes`
  0%, 100% {
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) rotate(5deg) scale(1.1);
  }
`;

const Popup = styled.div`
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 2rem;
  border-radius: 1rem;
  z-index: 100;
  text-align: center;
  font-size: 1.5rem;
  animation: ${lightning} 0.5s infinite alternate, ${spark} 1s infinite;
`;

const Search = () => {
  const query = useQuery();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState(query.get("search") || "");
  const [startingEffect, setStartingEffect] = useState();
  const { playingMode, category } = useParams();
  const [showPopup, setShowPopup] = useState(false);

  const inputRef = useRef(null);
  const clearButtonRef = useRef(null);
  const searchButtonRef = useRef(null);
  const [focusedIndex, setFocusedIndex] = useState(1);

  // Dodavanje efekta pri pokretanju
  useEffect(() => {
    const timeoutId = setTimeout(() => setStartingEffect("blur-search"), 1);
    inputRef.current.focus();
    return () => clearTimeout(timeoutId);
  }, []);

  // Navigacija pomoću strelica na tastaturi
  const handleKeyDown = useCallback(
    (event) => {
      switch (event.key) {
        case "ArrowRight":
        case "ArrowDown":
          setFocusedIndex((prev) => (prev + 1) % 3);
          event.preventDefault();
          break;
        case "ArrowLeft":
        case "ArrowUp":
          setFocusedIndex((prev) => (prev - 1 + 3) % 3);
          event.preventDefault();
          break;
        case "Enter":
          if (focusedIndex === 0) clear();
          else if (focusedIndex === 1) inputRef.current.focus();
          else if (focusedIndex === 2) searchButtonRef.current.click();
          event.preventDefault();
          break;
        default:
          break;
      }
    },
    [focusedIndex]
  );

  // Ažuriranje fokusa na dugmadima
  useEffect(() => {
    if (focusedIndex === 0) clearButtonRef.current.focus();
    else if (focusedIndex === 1) inputRef.current.focus();
    else if (focusedIndex === 2) searchButtonRef.current.focus();
  }, [focusedIndex]);

  // Funkcija za slanje pretrage
  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (searchValue === "AFAF") {
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 9000);
    } else {
      history.replace(
        category
          ? `/${playingMode}/category/${category}/?search=${searchValue}`
          : `/${playingMode}/?search=${searchValue}`
      );
    }
  };

  // Funkcija za čišćenje pretrage
  const clear = () => {
    setStartingEffect();
    setSearchValue("");
    setTimeout(() => {
      history.replace(
        category ? `/${playingMode}/category/${category}` : `/${playingMode}/`
      );
    }, 300);
  };

  return (
    <>
      <Container className={startingEffect}>
        <Box onSubmit={handleSubmit}>
          <ClearButton
            type="button"
            className="btn"
            onClick={clear}
            ref={clearButtonRef}
          >
            <i className="fa fa-times"></i>
          </ClearButton>
          <Input
            type="text"
            spellCheck={false}
            placeholder="Unesi ime glumca ili naziv filma ili godinu..."
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            ref={inputRef}
          />
          <SearchButton type="submit" className="btn" ref={searchButtonRef}>
            <i className="fa fa-search"></i>
          </SearchButton>
        </Box>
      </Container>
      {showPopup && <Popup>Created by AF - Master of React 2024</Popup>}
    </>
  );
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default Search;