import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./LateralBar.css";

const LateralBar = () => {
  const [navbar, setNavbar] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const buttonsRef = useRef([]);

  // Define navigation items without live section
  const navItems = [
    { label: "Filmovi", path: "/movie/", icon: "fa-film" },
    { label: "Pretraži", path: location.pathname.replace("menu", "search"), icon: "fa-search", show: location.pathname.includes("movie") },
    { label: "Odaberi kategoriju", path: "/movie/category/", icon: "fa-grip-lines", show: location.pathname.includes("movie") },
    { label: "TV Serije", path: "/series/", icon: "fa-video" },
    { label: "Pretraži", path: location.pathname.replace("menu", "search"), icon: "fa-search", show: location.pathname.includes("series") },
    { label: "Odaberi kategoriju", path: "/series/category/", icon: "fa-grip-lines", show: location.pathname.includes("series") },
    { label: "Podaci o računu", path: "/info/", icon: "fa-user-alt" },
  ].filter(item => item.show !== false); // Filter only items to display

  useEffect(() => {
    setNavbar(location.pathname.includes("menu"));
    if (navbar) setFocusedIndex(0); // Reset focus when navbar opens
  }, [location.pathname, navbar]);

  const closeSidebar = () => {
    setNavbar(false);
    history.replace(location.pathname.replace("/menu", ""));
  };

  const handleKeyDown = useCallback(
    (e) => {
      if (!navbar) return;
      e.preventDefault();
      if (e.key === "ArrowDown") {
        setFocusedIndex((prev) => (prev < buttonsRef.current.length - 1 ? prev + 1 : 0));
      } else if (e.key === "ArrowUp") {
        setFocusedIndex((prev) => (prev > 0 ? prev - 1 : buttonsRef.current.length - 1));
      } else if (e.key === "Enter" || e.key === "OK") {
        buttonsRef.current[focusedIndex]?.click();
      }
    },
    [focusedIndex, navbar]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    const currentButton = buttonsRef.current[focusedIndex];
    currentButton?.focus();
    currentButton?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [focusedIndex]);

  return (
    <>
      <div className={`lateralbar-container ${navbar ? "visible" : ""}`}>
        <div className="lateralbar">
          <button
            className="lateralbar-close-btn"
            onClick={closeSidebar}
            ref={(el) => (buttonsRef.current[0] = el)}
          >
            <i className="fas fa-times"></i>
          </button>
          <img className="lateralbar-logo" src="/img/banner_b.png" alt="Player Logo" />
          <ul className="lateralbar-list">
            {navItems.map((item, index) => (
              <li key={index}>
                <button
                  onClick={() => history.replace(item.path)}
                  ref={(el) => (buttonsRef.current[index + 1] = el)}
                >
                  <i className={`fas ${item.icon}`}></i> {item.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {navbar && <div className="overlay" onClick={closeSidebar}></div>}
    </>
  );
};

export default LateralBar;