import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { useHistory } from "react-router-dom";
import { useAuth } from "../other/auth";
import Popup from "./Popup/Popup";
import Cookies from 'js-cookie';

const glow = keyframes`
  0% { text-shadow: 0 0 5px #f5365c, 0 0 10px #f5365c; }
  100% { text-shadow: 0 0 20px #f5365c, 0 0 30px #f5365c; }
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh; /* Koristimo vh jedinice */
  top: 0;
  left: 0;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  z-index: 9999; /* Postavite visoki z-index da bude iznad svega */
  @media (max-width: 600px) {
    padding: 1rem;
    justify-content: flex-start;
    flex-direction: column;
  }
`;

const Box = styled.form`
  background: #1c1c1c;
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  max-width: 90vw;
  max-height: 80vh; /* Maksimalna visina */
  overflow-y: auto; /* Omogućava skrolanje */
  @media (max-width: 600px) {
    padding: 1rem;
    width: 100%;
  }
`;

const Header = styled.h2`
  color: white;
  text-align: center;
  animation: ${glow} 1.5s alternate infinite;
  margin-bottom: 1rem;
`;

const Subtitle = styled.h5`
  color: white;
  text-align: center;
  margin-bottom: 1.5rem;
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #666;
  width: 100%;
  color: white;
  background-color: #333;
  &:focus {
    border-color: #f5365c;
    box-shadow: 0 0 5px rgba(245, 54, 92, 0.6);
  }
`;

const Label = styled.label`
  color: white;
  margin-bottom: 0.5rem;
  display: block;
`;

const Button = styled.button`
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
  background-color: #f5365c;
  color: white;
  cursor: pointer;
  transition: all .3s ease-in-out;
  &:hover, &:focus {
    background-color: #dc3545;
    box-shadow: 0 0 5px rgba(220, 53, 69, 0.6);
  }
`;

const Login = React.memo(({ url }) => {
  const [dns, setDns] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [blur, setBlur] = useState({});
  const [m3u8, setM3u8] = useState(window.m3u8warning && !Cookies.get("m3u8_play"));
  const history = useHistory();
  const auth = useAuth();
  const inputRef = useRef(null);

  useEffect(() => {
    if (auth.isAuth()) {
      history.push(url || "/");
    } else {
      auth.authLogin(() => history.push(url || "/"));
    }
  }, [auth, history, url]);

  useEffect(() => {
    setBlur(m3u8 ? { filter: "blur(.3rem)" } : {});
  }, [m3u8]);

  useEffect(() => inputRef.current?.focus(), []);

  const remoteController = useCallback((event) => {
    let active = document.activeElement;
    if (event.key === "ArrowDown" && active.nextSibling) {
      active.nextSibling.focus();
    } else if (event.key === "ArrowUp" && active.previousSibling) {
      active.previousSibling.focus();
    } else if (event.key === "Enter") active.click();
  }, []);

  const login = useCallback((e) => {
    e.preventDefault();
    setBlur({ filter: "blur(.3rem)" });
    auth.signin(dns, username, password,
      () => window.location = "/",
      (title, description) => setShowPopup({ title, description })
    );
  }, [dns, username, password, auth]);

  const closePopup = () => {
    setBlur({});
    setShowPopup(false);
    inputRef.current?.focus();
  };

  return (
    <>
      <Container style={blur}>
        <Box onKeyDown={remoteController} onSubmit={login}>
          <Header>Dobrodošli</Header>
          <Subtitle>Unesite svoje podatke za prijavu</Subtitle>
          {!window.dns && (
            <>
              <Label>Provider URL:</Label>
              <Input ref={inputRef} type="text" placeholder="http://domain.com:80" onChange={(e) => setDns(e.target.value)} value={dns} />
            </>
          )}
          <Label>Korisničko ime</Label>
          <Input ref={window.dns ? inputRef : null} type="text" placeholder="Korisničko ime" onChange={(e) => setUsername(e.target.value)} value={username} />
          <Label>Lozinka</Label>
          <Input type="password" placeholder="****" onChange={(e) => setPassword(e.target.value)} value={password} />
          <Button type="submit">Prijava</Button>
        </Box>
      </Container>
      {showPopup && <Popup unsecure title={showPopup.title} description={showPopup.description} icon={"fas fa-user-times"} onclick={closePopup} />}
      {m3u8 && (
        <Popup unsecure title={"Informacije"} description={"Za prijavu koristite vaše korisničko ime i lozinku za playlistu, ne vašu email adresu."} icon={"fas fa-info-circle"} onclick={() => { Cookies.set("m3u8_play", 1, { expires: 365 }); setM3u8(false); }} />
      )}
    </>
  );
});

export default Login;