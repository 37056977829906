import React, { useEffect, useState } from 'react';
import Player from "../Player/Player";
import EpgListing from "./EpgListing";
import Channels from "./Channels";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { setPlaylist } from "../../actions/set-Playlist";
import { setGroupList } from "../../actions/set-Group";
import { setPlayingChannel } from "../../actions/playingChannel";  // Add this import to stop the player

import { resetMemory, setGroup } from "../../other/last-opened-mode";
import { loadGroup, loadPlaylist } from "../../other/load-playlist";
import { useParams, useHistory } from "react-router-dom";

import Popup from "../Popup/Popup";
import DB from "../../other/local-db";

const Main = styled.div`
  padding: 3vh 1vw;
  background-color: var(--first-color);
  height: calc(100vh - 6rem);
  margin-top: 3rem;
  z-index: 1;
  transition: filter 0.5s ease;
`;

const ChannelTitle = styled.h5`
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ContentContainer = styled.div`
  display: flex;
  height: calc(100vh - 6rem); /* Ensures full height */
`;

const PlayerContainer = styled.div`
  flex: 1;
  padding-left: 15px;
  max-height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  background-color: var(--first-color);
`;

const ChannelsContainer = styled.div`
  flex: 1;
  padding-left: 0;
  max-height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  background-color: var(--first-color);
`;

const MainLive = () => {
  const [blurBackground, setBlurBackground] = useState();
  const playingChannel = useSelector(state => state.playingCh);
  const playlist = useSelector(state => state.playlist);
  const dispatch = useDispatch();
  const history = useHistory();

  const { category } = useParams();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fun = async () => {
      setBlurBackground(((isNaN(category) || category === undefined) || history.location.pathname.includes("menu")) ? { filter: "blur(.5rem)", pointerEvents: "none" } : {});

      // Stop the player when switching categories or leaving the page
      dispatch(setPlayingChannel(null)); 

      if (category !== undefined && category !== 0) {
        let chs = await loadPlaylist("live", category);
        chs = chs || [];
        if (category === "fav")
          chs = chs.filter(x => DB.findOne("live", x.stream_id, true));
        dispatch(setPlaylist(chs));
        if (chs.length === 0)
          setShowPopup(true);
      } else if (resetMemory("live")) {
        await loadGroup("live").then(gps => {
          if (!gps || gps.length === 0) {
            history.replace("/");
            return;
          }
          gps.unshift({ category_name: "Only favorites", category_id: "fav" });
          setGroup(gps[1].category_id);
          dispatch(setGroupList(gps));
          history.replace("/live/category/" + gps[1].category_id + "/");
        });
      } else history.replace("/live/category/");
    }
    fun();
  }, [dispatch, category, history]);

  return (
    <div>
      <Main style={blurBackground}>
        <ContentContainer>
          <PlayerContainer>
            <ChannelTitle>{playingChannel ? playingChannel.name : "No channel selected"}</ChannelTitle>
            {playingChannel ? (
              <Player />
            ) : (
              <div style={{ color: "white", padding: "1rem" }}>Select a channel to play</div>
            )}
            {playingChannel && (
              <EpgListing Epg={playingChannel.epg_channel_id} Shift={playingChannel.shift} />
            )}
          </PlayerContainer>
          <ChannelsContainer>
            <Channels playlist={playlist} />
          </ChannelsContainer>
        </ContentContainer>
      </Main>
      {showPopup && <Popup title={`No stream found in selected category.`} icon={"fas fa-times"} onclick={() => {
        setBlurBackground({});
        setShowPopup(false);
        history.replace("/live/category/");
      }} />}
    </div>
  );
}

export default MainLive;
