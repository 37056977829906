import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getGroup } from "../../other/last-opened-mode";
import "./Groups.css";

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 30px;
  border: 1px solid var(--first-color);
  background-color: #333;
  color: #fff;
  transition: all 0.3s ease;
  font-size: 1rem;
  &:focus {
    border-color: var(--second-color);
    box-shadow: 0 0 0 0.2rem var(--second-color-shadow);
    outline: none;
    background-color: #444;
  }
  &::placeholder {
    color: #bbb;
  }
`;

const Groups = () => {
  const [navbarVisible, setNavbarVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const { playingMode, category } = useParams();
  const groupsTemp = useSelector((state) => state.groupsList);
  const [groups, setGroups] = useState(groupsTemp);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const buttonRefs = useRef([]);

  useEffect(() => {
    setNavbarVisible(isNaN(category));
  }, [category]);

  useEffect(() => {
    setSearchValue("");
    setGroups(groupsTemp);
  }, [groupsTemp]);

  useEffect(() => {
    if (!groups || groups.length === 0) {
      history.replace("/");
    }
  }, [groups, history]);

  const searchGroup = (text) => {
    setSearchValue(text);
    setGroups(
      text
        ? groupsTemp.filter((x) =>
            x.category_name.toLowerCase().includes(text.toLowerCase())
          )
        : groupsTemp
    );
  };

  const selectGroup = (gp) => {
    setNavbarVisible(false);
    if (gp === -1 && playingMode !== "live") {
      if (history.location.pathname !== `/${playingMode}/`) {
        setTimeout(() => history.replace(`/${playingMode}/`), 500);
      }
    } else {
      if (
        history.location.pathname !==
        `/${playingMode}/category/${groups[gp].category_id}/`
      ) {
        setTimeout(
          () =>
            history.replace(
              `/${playingMode}/category/${groups[gp].category_id}/`
            ),
          500
        );
      }
    }
  };

  const closeBar = () => {
    setNavbarVisible(false);
    history.goBack();
  };

  const handleKeyDown = useCallback(
    (e) => {
      switch (e.key) {
        case "ArrowUp":
          e.preventDefault();
          setFocusedIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : buttonRefs.current.length - 1
          );
          break;
        case "ArrowDown":
          e.preventDefault();
          setFocusedIndex((prevIndex) =>
            prevIndex < buttonRefs.current.length - 1 ? prevIndex + 1 : 0
          );
          break;
        case "Enter":
          e.preventDefault();
          if (buttonRefs.current[focusedIndex]) {
            buttonRefs.current[focusedIndex].click();
          }
          break;
        default:
          break;
      }
    },
    [focusedIndex]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (buttonRefs.current[focusedIndex]) {
      buttonRefs.current[focusedIndex].focus();
      buttonRefs.current[focusedIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [focusedIndex]);

  return (
    <>
      <div className={`groups-container ${navbarVisible ? "visible" : ""}`}>
        <div className="groups">
          <div className="groups-header">
            <Input
              className="form-control"
              type="text"
              spellCheck={false}
              placeholder="Pretrazi kategorije..."
              onChange={(e) => searchGroup(e.target.value)}
              value={searchValue}
            />
            <button
              className="groups-close-btn"
              onClick={closeBar}
              ref={(el) => (buttonRefs.current[0] = el)} // Dodajemo close button u ref listu
              tabIndex={0}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
          <ul className="groups-list">
            {playingMode !== "live" && (
              <li key={"GP-1"}>
                <button
                  tabIndex={0}
                  className="lateral-focus hvr-grow-shadow group"
                  onClick={() => selectGroup(-1)}
                  ref={(el) => (buttonRefs.current[1] = el)}
                >
                  All
                </button>
              </li>
            )}
            {groups &&
              Array.isArray(groups) &&
              groups.map((gp, id) => (
                <li key={"GP" + gp.category_id}>
                  <button
                    tabIndex={0}
                    id={gp.category_id === getGroup() ? "selectedGp" : ""}
                    className="lateral-focus hvr-grow-shadow group"
                    onClick={() => selectGroup(id)}
                    ref={(el) => (buttonRefs.current[id + 2] = el)} // +2 zbog close buttona i "All" dugmeta
                  >
                    {gp.category_name}
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>
      {navbarVisible && <div className="overlay" onClick={closeBar}></div>}
    </>
  );
};

export default Groups;