// src/components/LandscapeWarning.js

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const WarningContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 10000;
  font-size: 1.5rem;
`;

const LandscapeWarning = () => {
  const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
    };
    
    window.addEventListener("resize", handleOrientationChange);
    return () => window.removeEventListener("resize", handleOrientationChange);
  }, []);

  if (!isPortrait) return null;

  return (
    <WarningContainer className="landscape-warning">
      <p>Molimo, zakrenite svoj uređaj horizontalno za najbolji doživljaj.</p>
    </WarningContainer>
  );
};

export default LandscapeWarning;