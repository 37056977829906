import React, { useState, useEffect, useRef } from 'react';
import ReactNetflixPlayer from "../../other/Player-github/player-github";
import styled from 'styled-components';
import { useParams, useHistory } from "react-router-dom";
import { optimizeName } from "../../other/vod-series-name-optimizer";
import { useSelector } from "react-redux";
import DB from "../../other/local-db";

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  *:focus {
    outline: none; /* Uklanja žuti border */
  }
`;

const PlayerSeries = () => {
  const { category, season, episode, stream_id } = useParams();
  const history = useHistory();
  const tvseries = useSelector(state => state.playlist).find(x => parseInt(x.series_id) === parseInt(stream_id)).name;
  const streamsTemp = useSelector(state => state.playlistEpisodes);
  const [stream, setStream] = useState(null);
  const [streams, setStreams] = useState([]);
  const [streamStat, setStreamStat] = useState({});
  const [isReloading, setIsReloading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    if (!streamsTemp || streamsTemp.length === 0) {
      history.replace(`/series/`);
      return;
    }
    const updatedStreams = streamsTemp.map(x => ({
      ...x,
      playing: parseInt(x.episode_num || x.episode) === parseInt(episode),
      id: x.episode_num || x.episode,
      name: x.title
    }));
    if (isMounted.current) setStreams(updatedStreams);
    return () => {
      isMounted.current = false;
    };
  }, [streamsTemp, episode, history]);

  useEffect(() => {
    if (streams && streams.length > 0) {
      const selectedStream = streams.find(x => x.playing === true);
      let stat = DB.findOne("series", stream_id) || { id: stream_id, season, episode: selectedStream.id, start: 0, tot: 0 };
      if (stat && (parseInt(stat.season) !== parseInt(season) || parseInt(stat.episode) !== parseInt(episode))) {
        stat = { id: stream_id, season, episode: selectedStream.id, start: 0, tot: 0 };
      }
      setStreamStat(stat);
      DB.set("series", stream_id, stat);
      if (isMounted.current) setStream(selectedStream);
    }
  }, [streams, season, episode, stream_id]);

  const setStat = (stat) => {
    setStreamStat(stat);
    DB.set("series", stream_id, stat);
  };

  const handleEpisodeChange = (id) => {
    let next = streams.find(x => x.episode_num === id);
    if (next) {
      setIsReloading(true);
      history.replace(`/series/category/${category}/${stream_id}/play/season/${season}/episode/${next.episode_num}/`);
      setTimeout(() => setIsReloading(false), 0);
    }
  };

  if (isReloading) return null;

  return (
    <Container>
      {stream && (
        <ReactNetflixPlayer
          src={stream.direct_source || stream.url || stream.url2}
          title={optimizeName(tvseries)}
          titleMedia={optimizeName(tvseries)}
          extraInfoMedia={optimizeName(stream.title)}
          backButton={() => history.goBack()}
          FullPlayer
          autoPlay
          startPosition={streamStat ? parseInt(streamStat.start) : 0}
          overlayEnabled
          autoControllCloseEnabled
          Style
          primaryColor="var(--second-color)"
          secundaryColor="var(--first-color)"
          reprodutionList={streams.map((s, idx) => ({ ...s, key: `stream-${s.id}-${idx}` }))}
          playlistTitle={`Season ${season}`}
          syncDuration={(duration, percentage) => setStat({ ...streamStat, start: duration, tot: parseInt(percentage), episode, season })}
          dataNext={() => {
            let next = streams.find(x => x.episode_num > stream.episode_num);
            return { title: next ? `Next: ${next.title}` : "" };
          }}
          onClickItemListReproduction={(id) => handleEpisodeChange(id)}
          onEnded={() => {
            let next = streams.find(x => x.episode_num > stream.episode_num);
            if (!next) history.goBack();
            else history.replace(`/series/category/${category}/${stream_id}/play/season/${season}/episode/${next.episode_num}/`);
          }}
        />
      )}
    </Container>
  );
}

export default PlayerSeries;