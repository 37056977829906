import React, { useCallback } from "react";
import styled, { keyframes } from "styled-components";
import RowItem from "./RowItem";
import { Link, useHistory } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";

const Li = styled.li`
  color: white;
  margin: 4rem 0;
  & > div {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
  }
`;

const colorTransition = keyframes`
  0% {
    color: #ffffff;
  }
  50% {
    color: #FF0000;
  }
  100% {
    color: #ffffff;
  }
`;

const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0.3rem;
`;

const H3 = styled(Link)`
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.2;
  margin: 0;
  text-decoration: none;
  color: white; /* Zadrži bijelu boju teksta */
  padding: 0.5rem 0; /* Dodaj padding za bolji izgled */
  display: inline-block; /* Omogući da se ponaša kao bedž */
  border-bottom: 3px solid #e50914; /* Dodaj crvenu liniju ispod naslova */
  width: auto; /* Postavi širinu na auto da se prilagodi sadržaju */
  box-sizing: border-box; /* Uključi padding u ukupnu širinu */
`;

const ShowMoreButton = styled.button`
  margin-left: 1rem;
  background-color: transparent;
  border: none;
  color: white;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  display: inline-block;
  outline: none;
  text-align: center;
  transition: color 0.3s ease;
  &:hover,
  &:focus {
    color: #FF0000;
  }
  .icon {
    font-size: 2.2rem;
    margin-top: 0.2rem;
    transition: color 0.3s ease;
  }
  &:hover .icon,
  &:focus .icon {
    color: #FF0000;
  }
`;

const maxItem = 5;

const GroupRow = ({ category_id, name, style, playlist, isSeries, showAllItems }) => {
  const history = useHistory();

  const handleGroupClick = () => {
    if (category_id === "trending") {
      history.push(`/movie/category/trending/`);
    } else {
      history.push(`/${isSeries ? "series" : "movie"}/category/${category_id}/`);
    }
  };

  const itemsToShow = showAllItems ? playlist : playlist.slice(0, maxItem);
  const shouldShowButton = !showAllItems && playlist.length > maxItem;

  // Insert the "Show More" button as the sixth item if needed
  const displayItems = [...itemsToShow];
  if (shouldShowButton) {
    displayItems.splice(maxItem, 0, { isShowMoreButton: true });
  }

  const handleKeyDown = useCallback((event, index) => {
    const focusableElements = Array.from(document.querySelectorAll('[tabindex="0"]'));
    const currentIndex = focusableElements.indexOf(document.activeElement);
    switch (event.key) {
      case "ArrowRight":
        if (currentIndex < focusableElements.length - 1) {
          focusableElements[currentIndex + 1].focus();
        }
        break;
      case "ArrowLeft":
        if (currentIndex > 0) {
          focusableElements[currentIndex - 1].focus();
        }
        break;
      case "ArrowDown": {
        const nextRowIndex = currentIndex + maxItem + 1;
        if (nextRowIndex < focusableElements.length) {
          focusableElements[nextRowIndex - (nextRowIndex % maxItem)].focus();
        }
        break;
      }
      case "ArrowUp": {
        const previousRowIndex = currentIndex - maxItem - 1;
        if (previousRowIndex >= 0) {
          focusableElements[previousRowIndex - (previousRowIndex % maxItem)].focus();
        }
        break;
      }
      case "Enter":
        document.activeElement.click();
        break;
      default:
        break;
    }
    event.preventDefault();
  }, [itemsToShow.length, shouldShowButton]);

  return (
    playlist.length > 0 && (
      <Li style={style}>
        <GroupHeader>
          <H3 to={`/${isSeries ? "series" : "movie"}/category/${category_id}/`}>
            {name}
          </H3>
        </GroupHeader>
        <div>
          {displayItems.map((x, id) => (
            x.isShowMoreButton ? (
              <ShowMoreButton
                key="show-more-button"
                onClick={handleGroupClick}
                tabIndex="0"
                onKeyDown={(event) => handleKeyDown(event, itemsToShow.length)}
              >
                <div>PRIKAŽI VIŠE</div>
                <FaChevronDown className="icon" />
              </ShowMoreButton>
            ) : (
              <RowItem
                key={"vod" + (x.stream_id || x.series_id)}
                name={x.name}
                stream_icon={x.stream_icon || x.cover}
                stream_id={x.stream_id || x.series_id}
                stream_url={x.direct_source}
                category_id={category_id}
                container_extension={x.container_extension}
                isSeries={isSeries}
                existingTmdb={x.tmdb}
                totalItems={playlist.length}
                itemsPerRow={maxItem}
                tabIndex={0}
                rowIndex={Math.floor(id / maxItem)} // Izračunajte redak na temelju indeksa
                onKeyDown={(event) => handleKeyDown(event, id + 1)}
              />
            )
          ))}
        </div>
      </Li>
    )
  );
};

export default GroupRow;