import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
// import { getSingleEpgNow, downloadEpgData } from '../../other/epg-database'; // Uklonjeno
import { useSelector } from 'react-redux';
const dateFormat = require('dateformat');

const EpgChName = styled.div`
  position: absolute;
  background-image: linear-gradient(to bottom, #212121 -70%, transparent);
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
  z-index: 1000000000000000000000;
  & > div {
    padding-top: 2%;
    padding-bottom: 3%;
    position: relative;
    width: 70%;
    left: 15%;
  }
`;

const EpgInfoBox = styled.div`
  position: absolute;
  background-image: linear-gradient(to top, #212121 0%, transparent);
  height: 47%;
  & > div {
    padding-bottom: 5%;
    padding-top: 10%;
    position: relative;
    width: 70%;
    left: 15%;
  }
`;

const Title = styled.h2`
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

const Description = styled.h4`
  font-weight: 100;
  overflow-wrap: break-word;
  overflow: hidden;
  -webkit-line-clamp: 5;
  max-height: 30%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

const MainDiv = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  overflow: hidden;
  & > div {
    width: 100%;
    transition: bottom 0.5s ease 0s, top 0.5s ease 0s;
  }
  &:focus {
    outline-width: 0px;
  }
`;

const StopTime = styled.label`
  right: 0;
  position: absolute;
`;

const Logo = styled.div`
  padding: 0.1rem 0.1rem;
  border-radius: 0.2rem;
  text-align: center;
  width: 100%;
  font-weight: bold;
  min-height: 2.4rem;
  max-height: 2.4rem;
  & > img {
    max-height: 2.2rem;
    height: auto;
    width: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const FullscreenMag = ({ externalShow, cTitle, cDesc, cDuration }) => {
  const EpgChNameRef = useRef(0);
  const EpgInfoBoxRef = useRef(0);
  const mainRef = useRef(null);
  const playingChannel = useSelector((state) => state.playingCh);
  const h24Format = useSelector((state) => state.h24);
  const [epgNow] = useState(
    cDuration
      ? {
          title: cTitle,
          description: cDesc,
        }
      : false
  );
  const title = epgNow && epgNow.start ? epgNow.title : '';
  const description = epgNow && epgNow.start ? epgNow.description : '';
  const start = epgNow && epgNow.start ? dateFormat(new Date(epgNow.start), h24Format) : '';
  const stop = epgNow && epgNow.start ? dateFormat(new Date(epgNow.end), h24Format) : '';

  useEffect(() => {
    if (externalShow) {
      EpgInfoBoxRef.current && (EpgInfoBoxRef.current.style.bottom = '0px');
      EpgChNameRef.current && (EpgChNameRef.current.style.top = '0px');
    } else {
      EpgInfoBoxRef.current && (EpgInfoBoxRef.current.style.bottom = '-50%');
      EpgChNameRef.current && (EpgChNameRef.current.style.top = '-40%');
    }
  }, [externalShow]);

  return (
    <MainDiv tabIndex={0} ref={mainRef}>
      <EpgChName ref={EpgChNameRef} style={{ top: '-40%' }}>
        <div className="row">
          <div className="col-3">
            <Logo>
              <img src={playingChannel && playingChannel.stream_icon} loading={'lazy'} alt="" />
            </Logo>
          </div>
          <div className="col-auto">
            <h2>{playingChannel && playingChannel.name}</h2>
          </div>
        </div>
      </EpgChName>
      <EpgInfoBox ref={EpgInfoBoxRef} style={{ bottom: '-50%', display: !epgNow?.start && 'none' }}>
        <div>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <label>{start}</label>
          <StopTime>{stop}</StopTime>
        </div>
      </EpgInfoBox>
    </MainDiv>
  );
};

export default FullscreenMag;