import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  min-height: 2rem;
  height: 4rem;
  padding: 0.6rem;
  border-radius: .2rem;
  border: 0.1rem solid transparent;
  border-top-color: black;
  border-down-color: black;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center;
  position: relative;
  &:hover {
    background-color: #333;
    border-color: white;
  }
  &:focus {
    background-color: #444;
    outline: none;
    border-color: red;
  }
`;

const Number = styled.div`
  text-align: right;
  font-size: 1.2rem;
  color: #fff;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
  overflow: hidden;
  & > * {
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  & > h5 {
    -webkit-line-clamp: 1;
    font-size: 1.2rem;
    color: #fff;
  }
  & > label {
    -webkit-line-clamp: 2;
    font-weight: 300;
    font-size: 0.9rem;
    color: #bbb;
  }
`;

const Bar = styled.div`
  position: absolute;
  display: flex;
  height: 4%;
  bottom: 0;
  width: calc(100% - 1.9rem);
  background-color: var(--first-color);
  border-radius: 10rem;
  & > div {
    height: 100%;
    background-color: var(--second-color);
    border-radius: 10rem;
  }
`;

const Episode = ({ episode, duration, title, description, selected, playEpisode, percentage, buttonRef }) => {
  return (
    <Container
      className="row"
      style={selected ? { backgroundColor: "#333" } : {}}
      onClick={() => playEpisode(episode)}
      ref={buttonRef}
      tabIndex={0}
    >
      <div className="col-1 align-self-center">
        <Number>{episode}</Number>
      </div>
      <TitleContainer className="col-9 align-self-center">
        <h5>{title}</h5>
        {description && (
          <label>{description}</label>
        )}
      </TitleContainer>
      <div className="col-2 align-self-center">
        {secondsToHms(duration)}
      </div>
      {selected && percentage > 3 && percentage < 95 && (
        <Bar>
          <div style={{ width: percentage + "%" }} />
        </Bar>
      )}
    </Container>
  );
};

export default Episode;

const secondsToHms = d => {
  if (!d) return "";
  d = parseInt(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);
  if (h) {
    return `${h}:${m < 10 ? "0" + m : m}:${s < 10 ? "0" + s : s}`;
  }
  return `${m}:${s < 10 ? "0" + s : s}`;
};