import styled from "styled-components";
import React, { useEffect, useState, useRef } from 'react';
import { getVodInfo, getSeriesInfo } from "../../other/load-playlist";
import { optimizeName } from "../../other/vod-series-name-optimizer";
import { useParams, Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Seasons from "../Series/Seasons";
import DB from "../../other/local-db";

const Container = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
`;

const Box = styled.div`
  position: absolute;
  width: 70vw;
  left: 15vw;
  margin-top: 0vh;
  background-color: #181818;
  border-radius: .8rem;
  box-shadow: rgb(0 0 0 / 75%) 0px 3px 10px;
  overflow: hidden;
  transform: translateY(-50%);
  top: 50%;
  max-height: 95vh;
  padding: 1rem;
  overflow-y: auto;
  @media (max-width: 768px) {
    width: 90vw;
    left: 5vw;
    padding: 0.5rem;
  }
`;

const Header = styled.div`
  height: 35vh;
  max-height: 50vh;
  background-color: #000;
  overflow: hidden;
  background-color: var(--first-color);
  pointer-events: none;
  position: relative;
  z-index: 1;
`;

const ImgHovered = styled.div`
  height: 35vh;
  max-height: 50vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: .5rem .5rem 0 0;
  position: relative;
`;

const Body = styled.div`
  background-color: #181818;
  position: relative;
  padding: 1rem 2rem;
  color: white;
  overflow-y: auto;
  max-height: 60vh;
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 10vh;
  left: 2rem;
  height: 20vh;
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
`;

const RatingDiv = styled.div`
  color: #46d369;
  padding-right: .5rem;
`;

const DescriptionDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  cursor: pointer;
  @media (max-width: 768px) {
    -webkit-line-clamp: 4;
  }
`;

const TitleInfo = styled.span`
  color: #777;
  margin-right: .2rem;
  display: inline-flex;
`;

const TitleData = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ButtonMain = styled(Link)`
  color: black !important;
  -webkit-text-fill-color: black;
  -webkit-text-stroke-width: 0px;
  padding: .5rem 1.2rem;
  transition: all .3s ease;
  display: inline-block;
  &:focus {
    transform: scale(1.1);
    outline: none;
  }
`;

const ButtonFavorite = styled.button`
  color: black !important;
  -webkit-text-fill-color: black;
  -webkit-text-stroke-width: 0px;
  padding: .5rem 1.2rem;
  transition: all .3s ease;
  display: inline-block;
  &:focus {
    transform: scale(1.1);
    outline: none;
  }
`;

const ButtonSecond = styled.a`
  color: white !important;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 0px;
  padding: .5rem 1.2rem;
  transition: all .3s ease;
  display: inline-block;
  &:focus {
    transform: scale(1.1);
    outline: none;
  }
`;

const CloseBtn = styled.i`
  position: absolute;
  top: 1vh;
  right: 2rem;
  color: white;
  cursor: pointer;
  z-index: 100;
  font-size: large;
  text-align: right;
  background-color: rgb(0 0 0 / 75%);
  border-radius: 50%;
  padding: 0.2rem;
  @media (max-width: 768px) {
    right: 1rem;
    top: 0.5rem;
  }
  &:focus {
    transform: scale(1.1);
    outline: none;
  }
`;

const MoreInfo = ({ style }) => {
  const { category, stream_id, playingMode } = useParams();
  const history = useHistory();
  const stream = useSelector(state => state.playlist).find(x => playingMode === "series" ? parseInt(x.series_id) === parseInt(stream_id) : parseInt(x.stream_id) === parseInt(stream_id));
  const [expandDescription, setExpandDescription] = useState(false);
  const [Name, setName] = useState(stream && (optimizeName(stream.name)));
  const [Description, setDescription] = useState("");
  const [ImageSrc, setImageSrc] = useState(stream && (stream.stream_icon));
  const [Rating, setRating] = useState(false);
  const [youtubeId, setYoutubeId] = useState(null);
  const [backdropPath, setBackdropPath] = useState(null);
  const [Genres, setGenres] = useState("");
  const [Actor, setActor] = useState("");
  const [Year, setYear] = useState("");
  const [seasons, setSeasons] = useState([]);
  const [favorite, setFavorite] = useState(null);
  const modalRef = useRef(null);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    setFavorite(!!DB.findOne(playingMode, stream_id, true));

    async function fetchData() {
      if (!stream) {
        history.replace(`/${playingMode}/`);
        return;
      }
      const result = await (playingMode === "series" ? getSeriesInfo(stream.series_id, stream.name, false, stream.tmdb) : getVodInfo(stream.stream_id, stream.name, stream.tmdb));
      if (result && result.info && isMounted.current) {
        let info = result.info;
        info.name && setName(info.name);
        info.description && setDescription(info.description);
        info.image && setImageSrc(info.image);
        info.cover && setImageSrc(info.cover);
        info.youtube_trailer && setYoutubeId(info.youtube_trailer);
        info.backdrop_path && info.backdrop_path.length > 0 && setBackdropPath(info.backdrop_path[0]);
        info.genre && setGenres(info.genre);
        info.actors && setActor(info.actors);
        info.releasedate && setYear(info.releasedate);
        if (info.rating) {
          const push = { full: [], empty: [], half: false };
          const quotient = Math.floor(info.rating / 2);
          const remainder = info.rating % 2 > 0 ? 1 : 0;
          for (let i = 0; i < quotient; i++) push.full.push(1);
          for (let i = 0; i < 5 - remainder - quotient + (quotient === 1 ? 0 : 1); i++) push.empty.push(1);
          push.half = quotient === 1;
          setRating({ ...push });
        }
        playingMode === "series" && setSeasons(result.episodes);
      }
    }

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, [playingMode, stream, stream_id, history]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const focusableElements = modalRef.current.querySelectorAll('.play-button, .favorite-button, .trailer-button');
      const currentIndex = Array.from(focusableElements).indexOf(document.activeElement);
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          const nextIndex = (currentIndex + 1) % focusableElements.length;
          focusableElements[nextIndex].focus();
          break;
        case 'ArrowUp':
          event.preventDefault();
          const prevIndex = (currentIndex - 1 + focusableElements.length) % focusableElements.length;
          focusableElements[prevIndex].focus();
          break;
        case 'ArrowLeft':
        case 'ArrowRight':
          event.preventDefault(); // Onemogućava navigaciju lijevo-desno
          break;
        case 'Escape':
          handleClose();
          break;
        case 'Enter':
          event.preventDefault();
          if (document.activeElement.classList.contains('favorite-button')) {
            setFavoriteGlob(event);
          } else if (document.activeElement.classList.contains('play-button')) {
            document.activeElement.click();
          }
          break;
        default:
          break;
      }
    };

    const modalNode = modalRef.current;
    modalNode.addEventListener('keydown', handleKeyDown);

    // Automatski fokusiraj prvi element kada se modal otvori
    if (playingMode !== "series") {
      const playButton = modalNode.querySelector('.play-button');
      if (playButton) {
        playButton.focus();
      }
    }

    return () => {
      modalNode.removeEventListener('keydown', handleKeyDown);
    };
  }, [favorite, playingMode, category, stream_id, history]);

  const setFavoriteGlob = (event) => {
    event.stopPropagation();
    if (!!favorite)
      DB.del(playingMode, stream_id, true);
    else
      DB.set(playingMode, stream_id, { id: stream_id }, true);
    setFavorite(!favorite);
  }

  const handleClose = () => {
    document.body.style.filter = "none";
    history.goBack();
  }

  return (
    <Container style={style} ref={modalRef}>
      <Box>
        {!window.gSTB && (<CloseBtn className="fas fa-times-circle" onClick={handleClose} tabIndex="0"></CloseBtn>)}
        {youtubeId ? (
          <Header>
            <iframe
              frameBorder="0"
              height="100%"
              width="100%"
              title="youtube-pop"
              src={`https://youtube.com/embed/${youtubeId}?autoplay=1&controls=0&showinfo=0&autohide=1&mute=1&loop=1&disablekb=1&modestbranding=1&playlist=${youtubeId}&origin=http://localhost:3006`}
              style={{ pointerEvents: 'none', zIndex: 0 }}
            />
          </Header>
        ) : (
          <ImgHovered style={backdropPath || ImageSrc ? { backgroundImage: `url(${backdropPath || ImageSrc})`, height: 'auto', maxHeight: '50vh' } : { display: "none" }} />
        )}
        <Image src={ImageSrc} />
        <Body>
          <h1>{Name}</h1>
          <div className="row">
            <div className="col-8">
              <div style={{ display: "flex" }}>
                {Rating && (
                  <RatingDiv>
                    {Rating.full.map((x, i) => (<i key={"sf-" + i} className="fas fa-star"></i>))}
                    {Rating.half && (<i className="fas fa-star-half"></i>)}
                    {Rating.empty.map((x, i) => (<i key={"s-" + i} className="far fa-star"></i>))}
                  </RatingDiv>
                )}
                <label><b>{Year}</b></label>
              </div>
              <DescriptionDiv style={expandDescription ? { "-webkit-line-clamp": "unset" } : {}} onClick={() => setExpandDescription(!expandDescription)}>
                {Description}
              </DescriptionDiv>
            </div>
            <div className="col-4">
              {Actor && (
                <>
                  <TitleInfo>Glumci: </TitleInfo>
                  <TitleData>{Actor}</TitleData>
                  <br />
                </>
              )}
              {Genres && (
                <>
                  <TitleInfo>Žanrovi: </TitleInfo>
                  <TitleData>{Genres}</TitleData>
                  <br />
                </>
              )}
              {playingMode !== "series" && (
                <ButtonMain
                  type="button"
                  className="btn btn-light btn-block mb-2 mt-1 btn-info-1 play-button"
                  to={{
                    pathname: `/movie/category/${category}/${stream_id}/play/`,
                    search: window.location.search,
                  }}
                  tabIndex="0"
                >
                  <i className="fas fa-play mr-1"></i>
                  Pokreni
                </ButtonMain>
              )}
              <ButtonFavorite
                type="button"
                className="btn btn-warning btn-block mb-2 mt-1 btn-info-1 favorite-button"
                onClick={setFavoriteGlob}
                tabIndex="0"
              >
                <i className={`${favorite !== false ? "fas fa-star" : "far fa-star"} mr-1`}></i>
                {favorite !== false ? "Ukloni iz " : "Dodaj u "} favorite
              </ButtonFavorite>
              {youtubeId && (
                <ButtonSecond
                  type="button"
                  className="btn btn-block btn-info-2 trailer-button"
                  style={{ backgroundColor: "#c4302b" }}
                  href={`https://www.youtube.com/watch?v=${youtubeId}`}
                  target="_blank"
                  tabIndex="0"
                >
                  <i className="fab fa-youtube mr-1"></i>
                  Trailer
                </ButtonSecond>
              )}
            </div>
          </div>
          {playingMode === "series" && (
            <Seasons seasonData={seasons} cover={backdropPath || ImageSrc} />
          )}
        </Body>
      </Box>
    </Container>
  );
};

export default MoreInfo;