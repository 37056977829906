import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from "styled-components";
import { FaTv, FaFilm, FaVideo, FaInfoCircle } from 'react-icons/fa';
import "./MainMenu.css";  // Import the CSS file for hover effects

const PageBackground = styled.div`
  background-color: #000;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transform: ${({ active }) => (active ? "scale(1)" : "scale(0.95)")};
  transition: opacity 0.5s ease, transform 0.5s ease;
  visibility: ${({ active }) => (active ? "visible" : "hidden")}; 
`;

const Box = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Item = styled.li`
  list-style: none;
  margin: 1rem 0;
  
  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1c1c1c;
    color: white;
    font-size: 1.5rem;
    padding: 1rem;
    width: 100%;
    max-width: 400px;
    border: 2px solid #f5365c;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
      background-color: #f5365c;
      color: white;
      transform: scale(1.05);
      outline: 2px solid white;
    }
  }

  & > button > svg {
    margin-right: 1rem;
    font-size: 2rem;
  }
`;

const MainMenu = () => {
  const [active, setActive] = useState(false);  // Start inactive
  const history = useHistory();
  const buttonsRef = useRef([]); // Ref for tracking buttons
  const [focusedIndex, setFocusedIndex] = useState(0); // Track the currently focused index

  useEffect(() => {
    const timeout = setTimeout(() => {
      setActive(true);  // Make menu visible after a delay
      if (buttonsRef.current[0]) {
        buttonsRef.current[0].focus();  // Focus on the first button
      }
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const redirect = (page) => {
    setActive(false);  // Hide the menu
    setTimeout(() => {
      history.push(page);  // Change page after the transition
    }, 500);  // Delay matches the transition duration
  };

  const handleKeyDown = useCallback((e) => {
    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : buttonsRef.current.length - 1));
        break;
      case "ArrowDown":
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex < buttonsRef.current.length - 1 ? prevIndex + 1 : 0));
        break;
      case "Enter":
      case "OK": // Support 'OK' button for remote controls
        e.preventDefault();
        if (buttonsRef.current[focusedIndex]) {
          buttonsRef.current[focusedIndex].click();  // Trigger the button's click event
        }
        break;
      default:
        break;
    }
  }, [focusedIndex]);

  useEffect(() => {
    // Attach keydown listener when the component is mounted
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      // Clean up the event listener when the component is unmounted
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (buttonsRef.current[focusedIndex]) {
      buttonsRef.current[focusedIndex].focus();  // Focus on the current button
    }
  }, [focusedIndex]);

  return (
    <PageBackground active={active}>  {/* Control visibility and animation */}
      <Box>
        <ul>
          <Item>
            <button
              ref={(el) => (buttonsRef.current[0] = el)}
              onClick={() => redirect("/movie/")}
            >
              <FaFilm /> Filmovi
            </button>
          </Item>
          <Item>
            <button
              ref={(el) => (buttonsRef.current[1] = el)}
              onClick={() => redirect("/series/")}
            >
              <FaVideo /> TV Serije
            </button>
          </Item>
          <Item>
            <button
              ref={(el) => (buttonsRef.current[2] = el)}
              onClick={() => redirect("/info/")}
            >
              <FaInfoCircle /> Podaci o računu
            </button>
          </Item>
        </ul>
      </Box>
    </PageBackground>
  );
};

export default MainMenu;