// MainVod.js

import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { setPlaylist } from "../../actions/set-Playlist";
import { setGroupList } from "../../actions/set-Group";
import { resetMemory } from "../../other/last-opened-mode";
import { loadGroup, loadPlaylist } from "../../other/load-playlist";
import { useParams, useHistory, Route, Switch, useLocation } from "react-router-dom";
import BackgroundPlayer from "./BackgroundPlayer";
import GroupRow from "./GroupRow";
import MoreInfo from "./MoreInfo";
import PlayerSeries from "../Series/PlayerSeries";
import RowItem from "./RowItem";
import PlayerVod from "./PlayerVod";
import NavBar from "../NavBar";
import { VariableSizeList as List, VariableSizeGrid as Grid } from "react-window";
import { convertRemToPixels, convertVhToPixels } from "../../other/convert-rem";
import Popup from "../Popup/Popup";
import DB from "../../other/local-db";
import { Loading } from '../../other/Player-github/style';
import axios from 'axios';

const Container = styled.div`
  height: 100vh;
  overflow: auto;
  max-width: 100%;
  overflow-x: hidden;
  transition: filter 0.5s ease;
  background-color: var(--first-color);
  margin-top: ${convertRemToPixels(3)}px;
`;

const Spin = styled.div`
  height: 100vh;
  overflow: auto;
  overflow-y: auto;
  max-width: 100%;
  width: 100vw;
  overflow-x: hidden;
  background-color: transparent;
  position: absolute;
  z-index: 100000000000000000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GroupRowContainer = styled.ul`
  padding-top: 2rem;
  padding-bottom: 1rem;
  background-color: var(--first-color);
  list-style-type: none;
  padding-left: 1.7rem;
  max-width: 100vw;
  overflow: hidden;
  z-index: 1;
`;

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

const MainVod = () => {
  const { category, playingMode } = useParams();
  const [streamList, setStreamList] = useState([]);
  const [playlist, setPlaylistState] = useState([]);
  const [allItems, setAllItems] = useState(true);
  const [loadedCategory, setLoadedCategory] = useState(-1);
  const [showPopup, setShowPopup] = useState(false);
  const categories = useSelector(state => state.groupsList);
  const [blurBackground, setBlurBackground] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [sortMode, setSortMode] = useState('random'); // 'random', 'rating', 'popularity'
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const searchText = query.get("search");
  const [refresh, setRefresh] = useState(1);
  const firstRowItemRef = useRef(null);

  const showBlur = useCallback((mode) => {
    setBlurBackground(mode !== 0 ? { filter: "blur(.5rem)", pointerEvents: "none" } : {});
    if (mode === 0) setIsLoading(false);
  }, []);

  const getOtherCategoryName = (cat) => {
    if (cat === "fav") return "Favoriti";
    if (cat === "toend") return "Nastavi gledati";
    if (cat === "all") return playingMode === "series" ? "Sve Serije" : "Svi Filmovi";
    return "";
  };

  const getGroupRowPlaylist = (index) => {
    if (categories[index - 1].category_id === "all") {
      return playlist;
    }
    if (categories[index - 1].history === 1 || categories[index - 1].favorite === 1) {
      return playlist.filter(s => {
        const f = DB.findOne(playingMode, playingMode === "series" ? s.series_id : s.stream_id, categories[index - 1].favorite === 1);
        return f && (categories[index - 1].favorite === 1 || (f.tot > 3 && f.tot < 95));
      });
    } else {
      return playlist.filter(s => parseInt(s.category_id) === parseInt(categories[index - 1].category_id));
    }
  };

  useEffect(() => {
    if (location.pathname.match(/category/) && !category) showBlur(1);
    else location.pathname.match(/info|fullscreen|menu/) ? showBlur(1) : showBlur(0);
  }, [location.pathname, category, showBlur]);

  useEffect(() => {
    if (!searchText) {
      setPlaylistState(streamList);
      setShowPopup(false);
      return;
    }
    const searched = streamList.filter(x =>
      x.name.toLowerCase().includes(searchText.toLowerCase()) ||
      (Array.isArray(x.cast) && x.cast.some(c => c.toLowerCase().includes(searchText.toLowerCase())))
    );
    if (searched.length === 0) {
      setPlaylistState(streamList);
      setShowPopup(true);
    } else {
      setPlaylistState(searched);
    }
  }, [searchText, streamList]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseOutput = await axios.get('/output.json');
        const outputMovies = responseOutput.data;
        const responseApi = await loadPlaylist(playingMode, category || "ALL");
        const apiMovies = responseApi;
        const mergedMovies = apiMovies.map(apiMovie => {
          const outputMovie = outputMovies.find(o => o.stream_id === apiMovie.stream_id);
          return outputMovie ? { ...apiMovie, genres: outputMovie.genres || [], cast: outputMovie.cast || [], popularity: outputMovie.popularity || 0 } : apiMovie;
        });
        shuffle(mergedMovies);
        setStreamList(mergedMovies);
        setPlaylistState(mergedMovies);
        dispatch(setPlaylist(mergedMovies));
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching movies:', error);
      }
    };
    fetchData();
  }, [category, playingMode, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const reset = resetMemory(playingMode);
      if (reset === false && (location.pathname.match(/info|fullscreen|menu/) ||
        (location.pathname.match(/category/) && category === undefined) ||
        category === loadedCategory)) {
        return;
      }
      setLoadedCategory(category);
      setAllItems(((isNaN(category) && category === undefined) || location.pathname.match(/info|fullscreen|menu/)));
      showBlur(1);
      setIsLoading(true);
      setRefresh(0);
      if (reset || !categories.length) {
        await loadGroup(playingMode).then(gps => {
          if (!gps || !gps.length) {
            history.replace("/");
            return;
          }
          if (playingMode === "movie") {
            gps.unshift(
              { category_id: "all", category_name: "Svi Filmovi" }
            );
          } else if (playingMode === "series") {
            gps.unshift(
              { category_id: "all", category_name: "Sve Serije" }
            );
          }
          gps.unshift(
            { category_id: "fav", favorite: 1, category_name: "Favoriti" },
            { category_id: "toend", history: 1, category_name: "Nastavi gledati" }
          );
          dispatch(setGroupList(gps || []));
        });
      }
      await loadPlaylist(playingMode, category || "ALL").then(chs => {
        if (category && isNaN(category)) {
          chs = chs.filter(s => {
            const f = DB.findOne(playingMode, playingMode === "series" ? s.series_id : s.stream_id, category === "fav");
            return f && (category === "fav" || (f.tot > 3 && f.tot < 95));
          });
        }
        if (category === "all") {
          chs = streamList;
        }
        shuffle(chs);
        dispatch(setPlaylist(chs || []));
        showBlur(0);
        setStreamList(chs || []);
        setPlaylistState(chs);
        setRefresh(1);
      });
    };
    fetchData().catch(err => console.log(err));
  }, [dispatch, category, playingMode, location.pathname, categories, history, loadedCategory, showBlur]);

  const randomMovie = useMemo(() => {
    return searchRandomMovie(streamList);
  }, [streamList]);

  const sortMoviesByRating = useCallback((movies) => {
    return [...movies].sort((a, b) => b.rating - a.rating);
  }, []);

  const sortMoviesByPopularity = useCallback((movies) => {
    return [...movies].sort((a, b) => b.popularity - a.popularity);
  }, []);

  const toggleSortMode = (sortOption) => {
    setSortMode(sortOption);
    if (sortOption === 'rating') {
      setPlaylistState(sortMoviesByRating(playlist));
    } else if (sortOption === 'popularity') {
      setPlaylistState(sortMoviesByPopularity(playlist));
    } else {
      shuffle(playlist);
      setPlaylistState(playlist);
    }
  };

  const handleGenreChange = (genre) => {
    if (genre === '') {
      setPlaylistState(streamList);
    } else {
      const filteredMovies = streamList.filter(movie => {
        return movie.genres && movie.genres.includes(genre);
      });
      setPlaylistState(filteredMovies);
    }
  };

  const Row = ({ index, style }) => {
    if (index === 0) {
      return randomMovie && randomMovie.name ? (
        <div style={{ ...style }}>
          <BackgroundPlayer
            key={`bg-${randomMovie.stream_id || randomMovie.series_id}`}
            name={randomMovie.name}
            category_id={randomMovie.category_id}
            stream_id={randomMovie.stream_id || randomMovie.series_id}
            isSeries={playingMode === "series"}
            stream_icon={randomMovie.stream_icon || randomMovie.cover}
            existingTmdb={randomMovie.tmdb}
            title={category && (isNaN(category) ? getOtherCategoryName(category) : categories.find(x => parseInt(x.category_id) === parseInt(category)).category_name)}
            firstRowItemRef={firstRowItemRef}
            data-mainvod="true"
            onKeyDown={(e) => {
              if (e.key === 'ArrowUp') {
                e.preventDefault();
                const lastNavBarItem = document.querySelector('[data-navbar="true"]');
                if (lastNavBarItem) {
                  lastNavBarItem.focus();
                }
              }
            }}
          />
        </div>
      ) : (
        <div style={style}></div>
      );
    } else {
      const groupPlaylist = getGroupRowPlaylist(index);
      const displayedGroupPlaylist = sortMode === 'rating' ? sortMoviesByRating(groupPlaylist) :
        sortMode === 'popularity' ? sortMoviesByPopularity(groupPlaylist) : groupPlaylist;
      if (displayedGroupPlaylist.length === 0) {
        return null;
      }
      const categoryItem = categories[index - 1];
      const isContinueWatching = categoryItem.category_name === 'Nastavi gledati';
      return (
        <div style={style}>
          <GroupRow
            key={categoryItem.category_id}
            category_id={categoryItem.category_id}
            name={categoryItem.category_name}
            isSeries={playingMode === "series"}
            playlist={displayedGroupPlaylist}
            style={{ paddingLeft: "1.8rem", textDecoration: "none" }}
            isFirst={index === 1}
            isContinueWatching={isContinueWatching}
            firstRowItemRef={index === 1 ? firstRowItemRef : null}
          />
        </div>
      );
    }
  };

  const getItemSize = index => {
    if (index === 0) return convertVhToPixels("70vh");
    else if (getGroupRowPlaylist(index).length > 0) {
      return convertVhToPixels("15vw") * 1.8;
    } else return 0;
  };

  const getRowHeight = index => convertVhToPixels("15vw") * 1.5;

  const getColumnWidth = index => convertVhToPixels("16.6vw");

  const Cell = ({ columnIndex, rowIndex, style }) => {
    const startIndex = rowIndex * 6;
    const endIndex = startIndex + 6;
    const displayedPlaylist = sortMode === 'rating' ? sortMoviesByRating(playlist.slice(startIndex, endIndex)) :
      sortMode === 'popularity' ? sortMoviesByPopularity(playlist.slice(startIndex, endIndex)) : playlist.slice(startIndex, endIndex);
    const item = displayedPlaylist[columnIndex];
    if (!item) return null;
    return (
      <RowItem
        id={startIndex + columnIndex}
        key={"vod" + (item.stream_id || item.series_id)}
        name={item.name}
        stream_icon={item.stream_icon || item.cover}
        stream_id={item.stream_id || item.series_id}
        stream_url={item.direct_source}
        isSeries={playingMode === "series"}
        container_extension={item.container_extension}
        category_id={item.category_id}
        existingTmdb={item.tmdb}
        last={false}
        style={{ ...style, padding: ".5rem" }}
        ref={rowIndex === 0 && columnIndex === 0 ? firstRowItemRef : null}
        rowIndex={rowIndex}
        isGridItem={true}
      />
    );
  };

  return (
    <div>
      <NavBar onSortToggle={toggleSortMode} sortMode={sortMode} onGenreChange={handleGenreChange} />
      <Route path="/:playingMode/">
        {isLoading && (
          <Spin>
            <Loading color={"var(--second-color);"}>
              <div><div /><div /><div /></div>
            </Loading>
          </Spin>
        )}
        <Container style={blurBackground}>
          <GroupRowContainer style={{ paddingLeft: "0rem", paddingTop: 0 }}>
            {refresh !== 0 &&
              (allItems && playlist.length > 0 ?
                <List
                  height={convertVhToPixels("100vh") - convertRemToPixels(3.5)}
                  itemCount={categories.length + 1}
                  itemSize={getItemSize}
                  useIsScrolling={true}
                  style={{ overflowX: "hidden" }}
                >
                  {Row}
                </List>
                :
                <>
                  <div style={{ height: '2rem' }} />
                  <Grid
                    columnCount={6}
                    columnWidth={getColumnWidth}
                    height={convertVhToPixels("100vh") - convertRemToPixels(3.5)}
                    rowCount={Math.ceil(playlist.length / 6)}
                    rowHeight={getRowHeight}
                    width={convertVhToPixels("100vw")}
                    useIsScrolling={true}
                    style={{ overflowX: "hidden" }}
                  >
                    {Cell}
                  </Grid>
                </>
              )}
          </GroupRowContainer>
        </Container>
        {showPopup && (
          <Popup
            title={`Nije pronađen stream s nazivom '${searchText}'`}
            description={"Pretraga će biti resetirana."}
            icon={"fas fa-search"}
            onclick={() => {
              setShowPopup(false);
              history.replace(location.pathname.split("?")[0]);
            }}
          />
        )}
        <Switch>
          <Route path="/movie/category/:category/:stream_id/play/">
            <PlayerVod />
          </Route>
          <Route path="/series/category/:category/:stream_id/play/season/:season/episode/:episode/">
            <PlayerSeries />
          </Route>
          <Route path="/:playingMode/category/:category/:stream_id/info/">
            <MoreInfo key={"info-" + category} />
          </Route>
        </Switch>
      </Route>
    </div>
  );
};

export default MainVod;

function searchRandomMovie(playlist) {
  playlist = playlist.filter(x => !x.name.match(/xxx/i));
  const tempPlaylist = playlist.filter(x => x.rating >= 7);
  if (tempPlaylist.length > 0) {
    return { ...tempPlaylist[Math.floor(Math.random() * tempPlaylist.length)] };
  } else return { ...playlist[Math.floor(Math.random() * playlist.length)] };
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};