import React, { useState, useEffect, useRef, useCallback, forwardRef } from 'react';
import styled from "styled-components";
import Episode from './Episode';
import { useDispatch } from "react-redux";
import { setPlaylistEpisodes } from "../../actions/set-Playlist-Episodes";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DB from "../../other/local-db";
import { generateUrl } from "../../other/generate-url";

const Select = styled.select`
  display: flex;
  align-items: center;
  min-width: 4em;
  position: relative;
  font-size: 1.3rem;
  background-color: rgb(36, 36, 36);
  color: white;
  cursor: pointer;
  border: 0.1em solid rgb(77, 77, 77);
  border-radius: 0.2em;
  outline: 0;
  font-weight: 600;
  padding: 0.3rem 0.5rem;
  transition: all 0.2s ease;
  &:focus, &:hover {
    border-color: white;
    box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 25%);
  }
  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.2rem 0.4rem;
  }
`;

const Container = styled.div`
  margin-top: 1rem;
  overflow-y: auto;
  max-height: 60vh;
`;

const Seasons = forwardRef(({ seasonData, cover }, ref) => {
  const [seasons, setSeasons] = useState([]);
  const [streamStat, setStreamStat] = useState({});
  const [selectedSeason, setSelectedSeason] = useState();
  const { stream_id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [focusedIndex, setFocusedIndex] = useState(-1); // Start with -1 to focus on the select first
  const episodeRefs = useRef([]);
  const selectRef = useRef(null);

  useEffect(() => {
    if (!seasonData) {
      return;
    }
    const s = Object.keys(seasonData).map(x => {
      return {
        id: parseInt(x),
        name: "Season " + x,
        episodes: seasonData[x].map(y => {
          return {
            ...y,
            episode_num: parseInt(y.episode_num),
            url: y.url || y.direct_source || generateUrl("series", y.id, y.container_extension),
            url2: generateUrl("series", y.id, y.container_extension)
          };
        })
      };
    });
    if (s.length > 0) {
      setSeasons(s);
      let stat = DB.findOne("series", stream_id);
      if (!stat) {
        stat = { id: stream_id, season: s[0].id, episode: s[0].episodes[0].episode_num, start: 0 };
      }
      stat.season = parseInt(stat.season);
      stat.episode = parseInt(stat.episode);
      if (stat.tot > 95 || stat.tot < 3) {
        stat.tot = 0;
        let season = s.find(x => x.id === stat.season);
        if (season.episodes[season.episodes.length - 1].episode_num > stat.episode) {
          stat.episode++;
        } else {
          season = s.find(x => x.id === stat.season + 1);
          if (season && season.episodes && season.episodes.length > 0) {
            stat.season = season.id;
            stat.episode = season.episodes[0].episode_num;
          } else {
            stat = { id: stream_id, season: s[0].id, episode: s[0].episodes[0].episode_num, start: 0 };
          }
        }
      }
      setSelectedSeason(stat.season);
      setStreamStat(stat);
    }
  }, [seasonData, stream_id]);

  const playEpisode = (episode) => {
    const list = seasons.find(x => x.id === selectedSeason).episodes.map(x => { return { ...x, id: x.info.id }; });
    dispatch(setPlaylistEpisodes(list));
    history.push(location.pathname.replace("info", `play/season/${selectedSeason}/episode/${episode}`));
  };

  const handleKeyDown = useCallback((e) => {
    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex > -1 ? prevIndex - 1 : episodeRefs.current.length - 1));
        break;
      case "ArrowDown":
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex < episodeRefs.current.length - 1 ? prevIndex + 1 : -1));
        break;
      case "Enter":
        e.preventDefault();
        if (focusedIndex === -1) {
          selectRef.current.focus();
          // Simulate a click to open the dropdown
          const event = new MouseEvent('mousedown', {
            bubbles: true,
            cancelable: true,
            view: window
          });
          selectRef.current.dispatchEvent(event);
        } else if (episodeRefs.current[focusedIndex]) {
          episodeRefs.current[focusedIndex].click();
        }
        break;
      default:
        break;
    }
  }, [focusedIndex]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (focusedIndex === -1) {
      selectRef.current.focus();
    } else if (episodeRefs.current[focusedIndex]) {
      episodeRefs.current[focusedIndex].focus();
      episodeRefs.current[focusedIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [focusedIndex]);

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3>Episodes</h3>
        </div>
        <div className="col-4">
          <Select ref={selectRef} className="form-select btn-block seasons-button" onChange={e => setSelectedSeason(parseInt(e.target.value))} value={selectedSeason || (seasons[0] && seasons[0].id)} tabIndex="0">
            {seasons.map(x => <option value={x.id} key={"season-" + x.id}>{x.name} ({x.episodes.length} episodes)</option>)}
          </Select>
        </div>
      </div>
      <Container ref={ref} tabIndex={-1}>
        {streamStat && seasons.find(x => x.id === selectedSeason) && (
          seasons.find(x => x.id === selectedSeason).episodes.map((ep, index) => (
            <Episode key={"ep-" + ep.episode_num}
              episode={ep.episode_num}
              title={ep.title}
              description={ep.overview}
              duration={ep.info.duration_secs}
              selected={ep.episode_num === streamStat.episode}
              percentage={ep.episode_num === streamStat.episode ? streamStat.tot : false}
              playEpisode={playEpisode}
              buttonRef={(el) => episodeRefs.current[index] = el}
            />
          ))
        )}
      </Container>
    </>
  );
});

export default Seasons;