const isSmartTV = () => {
    const userAgent = navigator.userAgent;
    const smartTVKeywords = [
        "SMART-TV", "SmartTV", "AppleTV", "HbbTV", "Opera TV", "NetCast", "LG Browser", "PLAYSTATION",
        "Roku", "NintendoBrowser", "Android TV", "GoogleTV", "Viera", "AFTS", "BRAVIA", "PhilipsTV",
        "SonyTV", "Tizen", "TV Store", "WebTV", "InettvBrowser", "Maple", "Panasonic", "DuneHD", 
        "Yurow", "Aquos", "eDebug", "Firefox", "Samsung", "Hisense", "Sharp", "LGE", "NetTV",
        "Hybridcast", "HTML5Player", "HDMI", "NetRange", "Konka", "MiBOX", "AmazonWebAppPlatform"
    ];
    
    return smartTVKeywords.some(keyword => userAgent.includes(keyword));
};

export default isSmartTV;