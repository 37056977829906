import React, { useState, useContext, createContext } from "react";
import { Route, Redirect } from "react-router-dom";
import * as axios from "./axios";
import { setInfo } from "./user_info";
import Cookies from 'js-cookie';
import { initDb } from "./local-db";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

export function useProvideAuth() {
  const [auth, setAuth] = useState(0);

const signin = (dns, username, password, successFallback, failFallback) => {
  if (dns) axios.setDns(dns);

  axios.post("player_api.php", {
    username: username.trim(),
    password: password.trim(),
  }).then(result => {
    if (result) {
      if (result.data) result = result.data;
      else if (result.response && result.response.data) result = result.response.data;
    }
    if (result && result.user_info) {
      if (result.vietliveAF) axios.setDns(`${process.env.REACT_APP_VIETLIVEAF_API}webplayer`);
      if (result.user_info.auth === 0) {
        (typeof failFallback === 'function') && failFallback("Nije pronađeno korisničko ime", "Nije pronađeno korisničko ime s unesenim podacima." + (window.location.host.includes("vietliveAF.com") ? "<br/>Za prijavu koristite korisničko ime i lozinku vaše vietliveAF playliste, ne vašu email adresu." : ""));
      } else if (result.user_info.auth) {
        if (result.user_info.status !== "Active") {
          (typeof failFallback === 'function') && failFallback("Račun je istekao", `Račun je istekao ${new Date(parseInt(result.user_info.exp_date + "000")).toGMTString()}`);
        } else {
          setAuth(1);
          setInfo(result.user_info, result.server_info);
          initDb();
          (typeof successFallback === 'function') && successFallback();
        }
      }
    } else if (result.title) {
      (typeof failFallback === 'function') && failFallback(result.title, result.body);
    } else {
      (typeof failFallback === 'function') && failFallback("Greška na serveru", "Server nije generirao odgovor. Pokušajte kasnije #2");
    }
  }).catch(err => {
    // Remove the console.log and handle errors silently or via a fallback
    if (process.env.NODE_ENV !== 'production') {
      console.log(err);  // Only log in development mode
    }

    if (err && err.response && err.response.data && err.response.data.user_info && err.response.data.user_info.auth === 0) {
      (typeof failFallback === 'function') && failFallback("Nije pronađeno korisničko ime", "Nije pronađeno korisničko ime s unesenim podacima." + (window.location.host.includes("vietliveAF.com") ? "<br/>Za prijavu koristite korisničko ime i lozinku vaše vietliveAF playliste, ne vašu email adresu." : ""));
    } else {
      (typeof failFallback === 'function') && failFallback("Greška na serveru", "Server nije generirao odgovor. Pokušajte kasnije #1");
    }
  });
};

  const authLogin = (fallback) => {
    const dns = window.dns || Cookies.get("dns");
    const username = Cookies.get("username");
    const password = Cookies.get("password");

    if (username && password)
      signin(dns, username, password, fallback);
  };

  const signout = (action) => {
    setAuth(null);
    action && action();
  };

  const isAuth = () => {
    return !!auth;
  };

  return {
    signin,
    signout,
    isAuth,
    authLogin
  };
}

export function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.isAuth() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}