import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import allReducers from './reducer';  // Promijenjeno iz './reducers' u './reducer'
import LandscapeWarning from './components/LandscapeWarning';
import isSmartTV from './utils/isSmartTV';

const store = createStore(
  allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// Blokiranje ispisivanja u konzoli u produkciji
if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};
}

// Funkcija za isključivanje kursora
function hideCursor() {
  document.body.style.cursor = 'none';
}

// Postavljanje funkcionalnosti za Smart TV
if (isSmartTV()) {
  window.addEventListener('load', () => {
    hideCursor();  // Pozivamo funkciju za isključivanje kursora
  });
}

// Funkcija za omogućavanje full-screen mode - sada se ne koristi automatski
const setUpFullScreen = () => {
  // Ova funkcija se može koristiti ako želite da omogućite fullscreen na neki drugi događaj
};

// Uklanjanje događaja koji automatski omogućava fullscreen
// document.addEventListener('fullscreenchange', () => {
//   if (document.fullscreenElement) {
//     document.removeEventListener('click', enterFullScreen);
//   } else {
//     setUpFullScreen();
//   }
// });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
        <LandscapeWarning />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register();