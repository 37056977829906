import styled from 'styled-components';
import { useSelector } from 'react-redux';
import React, { useRef, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NavBar from '../NavBar';
import EpgChannel from './EpgChannel';
import TimeBar from './TimeBar/TimeBar';
import TimeLongLine from './TimeBar/TimeLongLine';
import Popup from '../Popup/Popup';
import { FixedSizeList as List } from 'react-window';
import { convertRemToPixels, convertVhToPixels } from '../../other/convert-rem';

const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  max-height: 100vh;
  background-color: var(--first-color);
  z-index: 20;
  overflow: hidden;
`;

const ChannelsContainer = styled.div`
  display: block;
  height: calc(100vh - 3.5rem);
  margin: 0 auto;
  flex-grow: 1;
  overflow-x: hidden;
  & > div {
    display: block;
    position: relative;
    scrollbar-width: none;
    height: 100%;
    scroll-behavior: smooth;
  }
  & > div > div {
    transform: translateY(0px);
    position: absolute;
    left: 0;
    contain: content;
    min-width: 408rem;
    max-width: 408rem;
  }
  @media (max-width: 600px) {
    height: calc(100vh - 4.5rem);
  }
`;

const EpgChannelsContainer = styled.div`
  top: 4rem;
  max-height: calc(100vh - 8rem);
`;

const ClosePopup = styled.a`
  color: white;
  position: absolute;
  right: 1rem;
  top: 0.2rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 2.5rem;
  transition: all .15s ease;
  z-index: 1000;
  &:hover {
    color: var(--second-color);
    cursor: pointer;
  }
`;

let isScrolling;

const EpgFullListing = () => {
  const channelsPlaylist = useSelector(state => state.playlist);
  const playingChannel = useSelector(state => state.playingCh);
  const h24 = useSelector(state => state.h24);
  const history = useHistory();
  const { category, date } = useParams();
  const scrollRef = useRef(null);
  const listRef = useRef(null);
  const day = date ? parseInt((new Date(date) - Date.now()) / (1000 * 60 * 60 * 24), 10) + 1 : 0;
  const leftScrollRef = useRef(0);
  const activeElement = useRef(null);

  const executeScroll = useCallback(() => {
    if (day === 0 && scrollRef.current) {
      scrollRef.current.scroll(new Date().getHours() * 230, 0);
    }
  }, [day]);

  const centerLogo = useCallback(() => {
    clearTimeout(isScrolling);
    isScrolling = setTimeout(() => {
      if (scrollRef.current && Math.abs(scrollRef.current.scrollLeft - leftScrollRef.current) > 100) {
        leftScrollRef.current = scrollRef.current.scrollLeft;
        Array.from(document.querySelectorAll(".chLogo")).forEach(x => x.style.left = `calc(${leftScrollRef.current}px + 140px)`);
      }
    }, 66);
  }, []);

  useEffect(() => {
    if (playingChannel) {
      listRef.current.scrollToItem(channelsPlaylist.findIndex(x => x.stream_id === playingChannel.stream_id), "center");
    }
    executeScroll();
    const timeoutId = setTimeout(() => {
      const toSelect = activeElement.current || document.querySelector(".isActive") || document.querySelector(".noActive");
      if (toSelect) toSelect.focus();
    }, 50);
    centerLogo();

    return () => clearTimeout(timeoutId);
  }, [playingChannel, channelsPlaylist, day, centerLogo, executeScroll]);

  const navButtonScroll = (val) => {
    if (scrollRef.current) {
      scrollRef.current.scroll({ left: scrollRef.current.scrollLeft + val, behavior: 'smooth' });
    }
  };

  const close = () => {
    setTimeout(() => {
      const selectedChElement = document.getElementById("selectedCh");
      if (selectedChElement) {
        selectedChElement.focus();
      } else {
        document.querySelector(".channel").focus();
      }
    }, 100);
    history.push("/live/category/" + category + "/");
  };

  const moveFocus = (event) => {
    if (event.keyCode === 27 || event.keyCode === 8) {
      close();
      return;
    }
    activeElement.current = document.activeElement;
    if (event.keyCode === 39 && activeElement.current.nextSibling) {
      activeElement.current.nextSibling.focus();
    } else if (event.keyCode === 37 && activeElement.current.previousSibling) {
      activeElement.current.previousSibling.focus();
    } else if (event.keyCode === 13) {
      activeElement.current.click();
    } else if (event.keyCode === 40 || event.keyCode === 38) {
      const start = parseInt(activeElement.current.dataset.start);
      let next = activeElement.current.parentElement.parentElement.nextElementSibling;
      while (!next?.children[1]?.children?.length && next) {
        next = event.keyCode === 40 ? next.nextElementSibling : next.previousSibling;
      }
      if (next && next.children[1].children.length > 0) {
        const elements = Array.from(next.children[1].children).sort((a, b) => {
          return Math.abs(parseInt(a.dataset.start) - start) - Math.abs(parseInt(b.dataset.start) - start);
        });
        const toFocus = elements[0];
        if (toFocus) {
          toFocus.focus();
          setTimeout(() => {
            document.getElementById(toFocus.id)?.focus();
          }, 10);
        }
      }
    }
  };

  const Row = ({ index, style }) => (
    <EpgChannel
      day={day}
      Shift={channelsPlaylist[index].shift}
      chId={channelsPlaylist[index].stream_id}
      key={index}
      Name={channelsPlaylist[index].name}
      Image={channelsPlaylist[index].stream_icon}
      Epg="EPG data unavailable"
      moveFocus={moveFocus}
      style={style}
      leftScroll={leftScrollRef.current}
      catchup={channelsPlaylist[index].tv_archive_duration}
    />
  );

  const height = convertVhToPixels("100vh") - convertRemToPixels(9);

  return (
    <Container>
      <NavBar day={day} />
      <ClosePopup onClick={close}><i className="far fa-times-circle"></i></ClosePopup>
      <ChannelsContainer ref={scrollRef} onScroll={centerLogo}>
        <div>
          <TimeBar day={day} h24={h24 === "HH:MM"} scrollBtn={navButtonScroll} />
          <EpgChannelsContainer>
            <List
              height={height}
              itemCount={channelsPlaylist.length}
              itemSize={convertRemToPixels(10)}
              style={{ overflowX: "hidden", overflowY: "auto", bottom: 0, marginTop: "0.6rem" }}
              ref={listRef}
              useIsScrolling={true}
            >
              {Row}
            </List>
            {day === 0 && (<TimeLongLine />)}
          </EpgChannelsContainer>
        </div>
      </ChannelsContainer>
      <Popup />
    </Container>
  );
};

export default EpgFullListing;